<template>
    <div class="myClassExchange">
        <div class="myClassExchange_search">
            <el-input v-model="input" placeholder="请输入兑换码"></el-input>
            <el-button type="primary"  @click="exchange()">兑换</el-button>
        </div>
        <div class="myClassExchange_list">
            <div class="myClassExchange_list_title">已兑换课程</div>
            <div class="myClassExchange_list_item" v-for="(item,index) in dataList" :key="index">
                <div class="myClassExchange_list_item_img">
                    <img :src="item.coursePackageCoverPath" alt=""> 
                </div>
                <div class="myClassExchange_list_item_content">
                    <p>{{item.packageName}}</p>
                    <p>兑换时间：{{item.exchangeTime}}</p>
                    <p>兑换成功的课程，请在【学习中心】-【我的课程】中学习</p>
                </div>
            </div>
            <div class="noMore" v-if="dataList.length ==0">
                <div class="coursediv_empty_img">
                    <img src="@/assets/class_empty.png" alt="">
                </div>
                暂无数据
            </div>

        </div>


        <el-dialog  :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <div class="popup">
                <p class="popup_title">您兑换的课程是：</p>
                <div class="popup_box">
                    <!-- <img src="" alt="">  -->
                    <div class="popup_box_content">
                        <p>{{exchangeDetail.courseName}}</p>
                        <p>
                            <span>有效期至：{{exchangeDetail.exchangeEfficientETime}}</span>
                            <span>价值：{{exchangeDetail.coursePackagePrice}}</span>
                        </p>
                        <!-- <p>共1个课包，12课时</p> -->
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">我再想想</el-button>
                <el-button type="primary" @click="doExchange()">确认兑换</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {package_list,get_exchange_info,do_exchange_info} from '../../api/myself';
export default {
    name:'myClassExchange',
    data(){
        return{
            dialogVisible:false,
            input:'',
            dataList:[],
            exchangeDetail:{}
        }
    },
    mounted(){
        this.package_list();
    },
    methods:{
        handleClose(){
            this.dialogVisible=false
        },
        package_list(){
            package_list().then(res=>{
                console.log(res);
                this.dataList = res.data.data;
            })
        },
        // 点击兑换
        exchange(){
            if(this.input!='' && this.input!=null && this.input!=undefined){   
                this.getExchange()
            }else{
                this.$message.error('请先填写兑换码')
            }
        },
        // 获取兑换商品信息
        getExchange(){
            var obj={
                redemptionCode:this.input
            }
            get_exchange_info(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.exchangeDetail=res.data.data
                    this.dialogVisible=true
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 点击兑换
        doExchange(){
            var obj={
                redemptionCode:this.input
            }
            do_exchange_info(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.dialogVisible=false
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        }

    }
}
</script>

<style>
.noMore {
    text-align: center;
    width: 100%;
    color: #999;
    font-size: 14px;
    margin: 40px 0;
}
</style>